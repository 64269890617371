<template>
  <div>
    <div
      :style="{ height: `${MENU_BAR_HEIGHT}px` }"
      class="bg-blue-darken-4"
    ></div>
    <v-container class="mb-16" style="margin-top: 37px">
      <v-row>
        <v-col>
          <div v-if="is404">
            <h1 class="text-h2 mb-4">Sorry, we can't find that page.</h1>
            <p class="text-body-1 mb-4">
              Please check the URL for typos, or maybe we made a mistake.
            </p>
            <v-btn color="red-darken-2" href="/" rounded="pill" variant="flat"
              >Return Home</v-btn
            >
          </div>

          <div v-else>
            <h1 class="text-h2 mb-4">Oops! The web is having a bad day.</h1>
            <p class="text-body-1 mb-4">
              Our apologies. Please try going back and refreshing, or check back
              in a moment.
            </p>
            <v-btn color="red-darken-2" href="/" rounded="pill" variant="flat"
              >Return Home</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>

    <DividerSection class="mb-16" />
  </div>
</template>

<script setup>
import { MENU_BAR_HEIGHT } from '#root/lib/consts'
import { usePageContext } from '#root/renderer/usePageContext'

const { is404 } = usePageContext()
</script>
